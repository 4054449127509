<template>
  <div class="tw-app-links-element">
    <!-- Go to app button -->
    <template v-if="isEditorMode">
      <div v-if="$slots.default && !!config.show?.generic" class="tw-app-links-element__main-button-wrapper md:!flex">
        <slot />
      </div>
    </template>
    <div v-else-if="slotDefault.length" class="tw-app-links-element__main-button-wrapper md:!flex">
      <template v-for="subEl of slotDefault" :key="subEl.id">
        <ElementWrapper
          v-if="subEl.options.visible && !!config.show?.generic && !!appLinks[storeLinkDefs.generic.urlKey]"
          :el="subEl"
          :page-options="pageOptions"
        />
      </template>
    </div>

    <!-- Store buttons -->
    <div
      v-if="hasActiveStoreButtons"
      class="tw-app-links-element__store-buttons"
      :class="{ 'pointer-events-none': isEditorMode }"
    >
      <template v-for="(storeLinkDef, key) in storeLinkDefs" :key="key">
        <a
          v-if="getStoreButtonShowStatus(key)"
          :href="appLinks[storeLinkDef.urlKey] || 'javascript:;'"
          target="_blank"
        >
          <img :src="getStoreBadgeImage(key, { isDark: !!config.dark })" :alt="storeLinkDef.storeName" />
        </a>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { getAssetFromCdn } from '@shared/utils/helpers';
import { getElementsBySlot } from '@shared/utils';
import { storeLinkDefs, type StoreLinkKey } from './definitions';

const props = defineProps({
  config: { type: Object as PropType<ElementOptions['applinks']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  element: { type: Object as PropType<ElementType<'applinks'>>, default: () => ({}) }
});

const appLinks = computed(() => props.pageData.applicationLinks || {});
const slotDefault = computed(() => {
  return getElementsBySlot(props.element.elements);
});
const hasActiveStoreButtons = computed(() => {
  const links: Record<string, any> = props.element.options?.show || {};
  return !!Object.keys(links || {}).filter((key: string) => (key === 'generic' ? false : !!links[key])).length;
});

function getStoreButtonShowStatus(key: StoreLinkKey) {
  const hasLink = props.isEditorMode ? true : !!appLinks.value?.[storeLinkDefs[key].urlKey];
  return key !== 'generic' && !!props.config.show?.[key] && hasLink;
}

function getStoreBadgeImage(key: string, options?: { isDark: boolean }) {
  const { isDark = false } = options || {};
  const dark_suffix = isDark ? '_dark' : '';
  return getAssetFromCdn(`editor/store-badges/${key}${dark_suffix}.png`);
}
</script>

<style lang="postcss" scoped>
.tw-app-links-element {
  &__main-button-wrapper {
    @apply block justify-center;
  }
  &__store-buttons {
    @apply mt-16 flex flex-wrap justify-center gap-16;
    img {
      @apply max-h-[34px];
    }
  }
}
</style>
